<template>
  <div class="content">
    <h1>Burning Time</h1>

    <div class="flex flex-col gap-4">
      <b-tabs pills v-model="pageIndex">
        <b-tab title="Tests"></b-tab>
        <b-tab title="Weights"></b-tab>
        <b-tab title="Scales"></b-tab>
      </b-tabs>

      <samples v-if="pageIndex === 0" />
      <scale-weights v-if="pageIndex === 1" />
      <scale-devices v-if="pageIndex === 2" />
    </div>
  </div>
</template>

<script>
const Samples = () => import('@/components/lifetime_samples/Samples.vue');
const ScaleWeights = () => import('@/components/scale/Weights.vue');
const ScaleDevices = () => import('@/components/scale/Devices.vue');

export default {
  name: 'LifetimeSamples',
  components: {
    Samples,
    ScaleWeights,
    ScaleDevices,
  },
  data() {
    return {
      pageIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
